import { MESSAGE_VARIANT } from "../../constants/constants";
import { messageConstant } from "./messageAction";

const initialState = {
  message: "",
  messageType: ""
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case messageConstant.SET_ERROR_MESSAGE: return {
      ...state,
      message: action.payload.message,
      messageType: MESSAGE_VARIANT.ERROR
    };
    case messageConstant.SET_SUCCESS_MESSAGE: return {
      ...state,
      message: action.payload.message,
      messageType: MESSAGE_VARIANT.SUCCESS
    };
    default: return state;
  }
};

export default messageReducer;