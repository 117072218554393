/* eslint-disable multiline-comment-style */
import api_end_point from "../../../API/api-end-point";
import apiServices from "../../../API/api_service";

const sSOForgetPassword = async (values) => {
  const data = {
    username: values.username,
  };
  const url = api_end_point.SSO_FORGET_PASSWORD;
  const result = await apiServices.post(url, data);
  return result;
};

const sSOConfirmForgetPassword = async (values) => {
  const url = api_end_point.SSO_CONFIRM_FORGET_PASSWORD;
  const data = {
    username: values.username,
    confirmation_code: values.confirmationCode,
    password: values.password,
  };
  const result = await apiServices.post(url, data);
  return result;
};

const sSOResendCode = async (values) => {
  const data = {
    username: values.username,
  };
  const url = api_end_point.SSO_RESEND_CODE;
  const result = await apiServices.post(url, data);
  return result;
};

export {
  sSOForgetPassword,
  sSOConfirmForgetPassword,
  sSOResendCode,
}