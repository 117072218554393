import React, { useState } from 'react'

const Dropdown = ({ options, selectedOption, setSelectedOption }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOptionClick = (option) => {
    setSearchTerm('');
    setSelectedOption(option?.value);
  };

  return (
    <div className="dropdown">
      <button className="form-select country-select h-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
        {selectedOption}
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <input type="text" className="form-control" placeholder="Search..." value={searchTerm} onChange={handleInputChange} />
        </li>
        <li>
          <ul className='dropdown-options list-style-none'>
            {filteredOptions.map((option, index) => (
              <li className='cursor-pointer' key={index} onClick={() => handleOptionClick(option)}>
                {option.label}
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div >
  )
}

export default Dropdown