/* eslint-disable import/no-anonymous-default-export */
import api_end_point from "./api-end-point";
import { PROJECT_TYPES, PROJECT_TYPES_URL } from "../constants/project-types";

const getHeaders = () => {
  const headers = {};
  const apiKey = "auth_key";
  headers[apiKey] = "C9941611DF64B";
  return headers;
};

const post = async (url, data = {}, type = PROJECT_TYPES.PROJECT_TYPES_NATURE) => {
  let response = {};
  const URL = `${api_end_point[PROJECT_TYPES_URL[type]]}${url}`;

  await fetch(`${URL}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: getHeaders(),
  }).then(async (result) => {
    response = await result.json();
  }).catch((error) => {
    console.log(error);
  });
  return response;
};

const get = async (url, type = PROJECT_TYPES.PROJECT_TYPES_NATURE) => {
  let response = {};
  const URL = `${api_end_point[PROJECT_TYPES_URL[type]]}${url}`;

  await fetch(`${URL}`, { method: "GET" }).then(async (result) => {
    response = await result.json();
  }).catch((error) => {
    console.log(error);
  });
  return response;
};

export default {
  get,
  post,
};
