/* eslint-disable multiline-comment-style */
import api_end_point from "../../../API/api-end-point";
import apiServices from "../../../API/api_service";

const checkERC20Validation = async (data) => {
  const url = api_end_point.ERC_20_VALIDATION;
  const result = await apiServices.post(url, data);
  return result;
};

const multiLogin = async (values) => {
  const data = {
    login: values.login,
    password: values.password,
  };
  const url = api_end_point.AUTH_MULTI_LOGIN;
  const result = await apiServices.post(url, data);
  return result;
};

const getLoginUserDetail = async (data) => {
  const url = api_end_point.LOGIN_USER_DETAILS;
  const res = await apiServices.post(url, data);
  return res;
};

export {
  checkERC20Validation,
  multiLogin,
  getLoginUserDetail,
}