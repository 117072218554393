const PROJECT_TYPES = {
  PROJECT_TYPES_NATURE: "nature",
  PROJECT_TYPES_ENERGY: "energy",
};

const PROJECT_TYPES_URL = {
  nature: "NATURE_BASE_URL",
  energy: "ENERGY_BASE_URL",
};
export { PROJECT_TYPES, PROJECT_TYPES_URL };
