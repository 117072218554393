/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import CryptoJS from "crypto-js";
import mailIcon from "../../assets/icons/mail-icon.svg";
import padLockIcon from "../../assets/icons/padlock-icon.svg";
import visibleEyeIcon from "../../assets/icons/visible-eye-icon.svg";
import hideEyeIcon from "../../assets/icons/hide-eye-icon.svg";
import Regex from "../../constants/regex";
import useAlert from "../../hooks/use-alert";
import { multiLogin } from "./services/loginService";
import loginSubmit from "../../utils/login_submit";

const Login = () => {
  const secretPass = "SUNSET.ACCOUNT";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { isLoggedIn, loginUserDetail } = useSelector((state) => state.loginReducer);

  const [isViewPassword, setIsViewPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);

  if (isLoggedIn) {
    const user_id = loginUserDetail?.user_id;
    const access_token = loginUserDetail?.access_token;

    window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}?user_id=${user_id}&access_token=${access_token}`);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    let remEmail = localStorage.getItem("rem_email");
    let remPass = localStorage.getItem("rem_pass");

    if (remEmail && remPass) {
      setIsRememberMe(true);
      formik.setFieldValue("login", decryptData(remEmail));
      formik.setFieldValue("password", decryptData(remPass));
    }
  }, []);

  const initialValues = {
    login: "",
    password: "",
  };

  const formSchema = Yup.object().shape({
    login: Yup.string()
      .trim()
      .required("Please enter email id")
      .matches(Regex.mailRegex, "Invalid email"),
    password: Yup.string()
      .trim()
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //   "The password must have at least one uppercase, one lowercase, one numeric digit, one special character and a minimum of eight characters."
      // )
      .required("Please enter password"),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      values.login = values.login.trim();
      values.password = values.password.trim();
      const result = await multiLogin(values);
      if (result && result.status) {
        const res = await loginSubmit(result, dispatch, showAlert);
        if (isRememberMe) {
          localStorage.setItem("rem_email", encryptData(values.login));
          localStorage.setItem("rem_pass", encryptData(values.password));
        } else {
          localStorage.removeItem("rem_email");
          localStorage.removeItem("rem_pass");
        }
        const urlParams = new URLSearchParams(window.location.search);
        const redirect_url = urlParams.get('redirect_url');

        const user_id = loginUserDetail?.user_id;
        const access_token = loginUserDetail?.access_token;

        if (result?.tsp?.is_admin) {
          window.location.replace(`${process.env.REACT_APP_ADMIN_NATURE_URL}?user_id=${user_id}&access_token=${access_token}`);
        } else if (res && res === "/dashboard" && redirect_url) {
          window.location.replace(`${redirect_url}?user_id=${user_id}&access_token=${access_token}`);
        } else if (res && res === "/dashboard") {
          window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}?user_id=${user_id}&access_token=${access_token}`);
        } else {
          navigate(res);
        }
      } else if (result && !result.status) {
        showAlert("ERROR", "ERROR", result.message);
      }

      setIsLoading(false);
    },
  });

  const encryptData = (text) => {
    const data = CryptoJS.AES.encrypt(JSON.stringify(text), secretPass).toString();
    return data;
  };

  const decryptData = (text) => {
    const bytes = CryptoJS.AES.decrypt(text, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  };

  return (
    <div className="page-content bg-style no-sidebar w-100 float-start">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-5 mb-4">
            <div className="content-box-style">
              <h4 className="text-uppercase text-center mb-5">Login</h4>
              <form action="" className="form-style">
                <div className="row">
                  <div className="col-12 mb-4">
                    <div className="input-group field-style">
                      <span className="input-group-text" id="basic-addon1">
                        <img src={mailIcon} className="img-fluid" alt="" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Input your Email Id"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="login"
                        {...formik.getFieldProps("login")}
                        required
                      />
                    </div>
                    {(formik.touched.login && formik.errors.login) && (
                      <p className="text-danger mb-0">
                        <small>{formik.errors.login}</small>
                      </p>
                    )}
                  </div>
                  <div className="col-12 mb-4">
                    <div className="input-group field-style">
                      <span className="input-group-text" id="basic-addon1">
                        <img src={padLockIcon} className="img-fluid" alt="" />
                      </span>
                      <input
                        type={isViewPassword ? "password" : "text"}
                        className="form-control password"
                        placeholder="Input your password"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        name="password"
                        {...formik.getFieldProps("password")}
                        required
                      />
                      <span className="input-group-text show-icon">
                        {
                          isViewPassword ?
                            <img src={hideEyeIcon} className="img-fluid svg-icon-size" alt="" onClick={() => setIsViewPassword(!isViewPassword)} />
                            :
                            <img src={visibleEyeIcon} className="img-fluid svg-icon-size" alt="" onClick={() => setIsViewPassword(!isViewPassword)} />
                        }
                      </span>
                    </div>
                    {(formik.touched.password && formik.errors.password) && (
                      <p className="text-danger mb-0">
                        <small>{formik.errors.password}</small>
                      </p>
                    )}
                  </div>
                  <div className="col-12 d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start justify-content-md-between">
                    <div className="form-check mb-4">
                      <input className="form-check-input" type="checkbox" checked={isRememberMe} id="flexCheckDefault" onChange={() => setIsRememberMe(!isRememberMe)} />
                      <label className="form-check-label" htmlFor="flexCheckDefault">Remember me</label>
                    </div>
                    <NavLink to="/forgot-password" className="text-decoration-underline fw-semibold text-green">Forgot Password?</NavLink>
                  </div>
                  <div className="col-12 mt-4">
                    {
                      isLoading ?
                        <button type="submit" className="btn mx-auto" disabled>
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </button>
                        :
                        <button type="submit" onClick={formik.handleSubmit} className="btn btn-submit mx-auto">Log In</button>
                    }
                  </div>
                  <div className="col-12 mt-4 text-center">
                    <div className="text-center">Don't have an account?
                      <NavLink to="/signup" className="text-decoration-underline fw-semibold text-green" style={{ marginLeft: "5px" }}>Sign up</NavLink>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
