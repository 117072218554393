export const loginConstants = {
  SET_USER_LOGIN_DETAIL: "SET_USER_LOGIN_DETAIL",
  SET_IS_LOGGED_IN: "SET_IS_LOGGED_IN",
  SET_USERNAME: "SET_USERNAME",
}

const setUserLoginDetail = (values) => {
  return {
    type: loginConstants.SET_USER_LOGIN_DETAIL,
    payload: values,
  };
};

const setUserLoginStatus = (values) => {
  return {
    type: loginConstants.SET_IS_LOGGED_IN,
    payload: {
      status: values,
    },
  };
};

const setUserName = (values) => {
  return {
    type: loginConstants.SET_USERNAME,
    payload: {
      username: values.username,
    },
  };
};

export {
  setUserLoginDetail,
  setUserLoginStatus,
  setUserName,
}