/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable no-negated-condition */
import { setUserLoginDetail, setUserLoginStatus, setUserName } from "../modules/login/redux/loginAction";
import { ACCESS_TOKEN_TYPES, USER_ID_TYPES } from "../constants/localstorage-types";
import { PROJECT_TYPES } from "../constants/project-types";
import { getLastTSPDetail } from "../utils/token";

const loginSubmit = async (data, dispatch, showAlert, page = "") => {
  const multi_tsp = await getLastTSPDetail(data);
  if (page === "") {
    if (data.otp_verified === false) {
      const values = {
        username: data.tsp.user_email
      };
      showAlert("SUCCESS", "Success", data.message);
      await dispatch(setUserName(values));
      const nav = "/confirm_otp";
      return nav;
    } else {
      let loginUserDetail = {
        user_email: data?.user_email,
        user_name: data?.user_name,
        aws_access_token: data?.tsp?.aws_access_token,
        aws_session: data?.tsp?.aws_session,
        is_admin: data?.tsp?.is_admin,
        multi_tsp: data?.multi_tsp ? data.multi_tsp : null
      }

      loginUserDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]] = multi_tsp?.user_id ? multi_tsp.user_id : null;
      loginUserDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]] = multi_tsp?.access_token ? multi_tsp.access_token : null;
      loginUserDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]] = data?.tsp?.user_id;
      loginUserDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]] = data?.tsp?.access_token;

      if (data?.tsp?.aws_session !== "") {
        loginUserDetail["otp_check"] = true;
        await dispatch(setUserLoginDetail(loginUserDetail));
        const nav = "/submit_otp";
        return nav;
      } else {
        loginUserDetail["status"] = data?.status
        loginUserDetail["otp_check"] = false;

        await dispatch(setUserLoginDetail(loginUserDetail));
        showAlert("SUCCESS", "Success", "User login Successfully");
        await dispatch(setUserLoginStatus(true));
        const nav = "/dashboard";
        return nav;
      }
    }
  } else {
    let loginUserDetail = {
      user_email: data?.user_email,
      user_name: data?.user_name,
      status: data?.status,
      otp_check: true,
      aws_access_token: data?.tsp?.aws_access_token,
      aws_session: data?.tsp?.aws_session,
      is_admin: data?.tsp?.is_admin,
      multi_tsp: data?.multi_tsp ? JSON.stringify(data.multi_tsp) : null
    }

    loginUserDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]] = multi_tsp?.user_id ? multi_tsp.user_id : null;
    loginUserDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_ENERGY]] = multi_tsp?.access_token ? multi_tsp.access_token : null;
    loginUserDetail[USER_ID_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]] = data?.tsp?.user_id;
    loginUserDetail[ACCESS_TOKEN_TYPES[PROJECT_TYPES.PROJECT_TYPES_NATURE]] = data?.tsp?.access_token;

    await dispatch(setUserLoginDetail(loginUserDetail));
    showAlert("SUCCESS", "Success", "User login successfully");
    await dispatch(setUserLoginStatus(true));
    const nav = "/dashboard";
    return nav;
  }
};
export default loginSubmit;
