import { combineReducers } from "redux";
import hookReducer from "../hooks/redux/hookReducer";
import homeReducer from "../modules/home/redux/homeReducer";
import loginReducer from "../modules/login/redux/loginReducer";
import messageReducer from "./messageRedux/messageReducer";
import alertReducer from "../hooks/redux/hookReducer";

const rootReducer = combineReducers({
  hookReducer,
  homeReducer,
  loginReducer,
  messageReducer,
  alertReducer,
});

export default rootReducer;
