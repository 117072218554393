/* eslint-disable multiline-comment-style */
import api_end_point from "../../../API/api-end-point";
import apiServices from "../../../API/api_service";

const validateQrCodeAPI = async (values) => {
  const url = api_end_point.VERIFY_QRCODE;
  const result = await apiServices.post(url, values);
  return result;
};

export {
  validateQrCodeAPI,
}