import React, { useEffect } from "react";
import ConfirmPasswordForm from "../sign-up/components/confirm-password-form";

const VerifyOtp = () => {
  const backDetail = {
    url: "/signup",
    title: "Back to Sign Up"
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-content bg-style no-sidebar w-100 float-start">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-5 mb-4">
            <div className="content-box-style">
              <h4 className="text-uppercase text-center">Verification</h4>
              <p className="font-16 font-w-400 font-999FAE mb-lg-5 text-center">
                We have just sent you an email with OTP, <br />
                please enter below to verify
              </p>
              <ConfirmPasswordForm backDetail={backDetail} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
