
const getTSPIds = (multi_tsp) => {
  if (multi_tsp) {
    multi_tsp = JSON.parse(multi_tsp);
    let person = {};
    Object.keys(multi_tsp).forEach(index => {
      person[index] = multi_tsp[index].user_id;
    });
    return person;
  }
}

const getLastTSPDetail = (data) => {
  let multi_tsp = data?.multi_tsp;
  if (multi_tsp) {
    let person = [];
    Object.keys(multi_tsp).forEach(index => {
      person.push(multi_tsp[index]);
    });
    const totalTSP = person.length - 1;
    return person[totalTSP];
  }
}

export {
  getTSPIds,
  getLastTSPDetail,
};
