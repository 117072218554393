/* eslint-disable default-param-last */
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { loginConstants } from "./loginAction";

const initialState = {
  loginUserDetail: {},
  isLoggedIn: false,
  username: "",
};



const persistConfig = {
  storage,
  key: "sunset-login",
  whitelist: ["loginUserDetail", "username"],
  transforms: [
    encryptTransform({
      secretKey: 'sunset-account-persist-secure',
      onError: function (error) {
        // Handle the error.
      }
    })
  ]
};

const loginReducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case loginConstants.SET_USER_LOGIN_DETAIL:
      return {
        ...state,
        loginUserDetail: action.payload,
      };
    case loginConstants.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload.status,
      };
    case loginConstants.SET_USERNAME:
      return {
        ...state,
        username: action.payload.username,
      };

    default:
      return state;
  }
});

export default loginReducer;