import React from "react";
import ConfirmPasswordForm from "../sign-up/components/confirm-password-form";

const ConfirmOTP = () => {
  const backDetail = {
    url: "/login",
    title: "Back to Log In"
  }

  return (
    <div className="page-content bg-style no-sidebar w-100 float-start">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-5 mb-4">
            <div className="content-box-style">
              <h4 className="text-uppercase text-center">Confirm OTP</h4>
              <p className="font-16 font-w-400 font-999FAE mb-lg-5 text-center">
                Enter your OTP from your email
              </p>

              <ConfirmPasswordForm backDetail={backDetail} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOTP;
