import { getLoginUserDetail } from "../modules/login/services/loginService";

const isLogin = async (setIsLoading, loginUserDetail) => {
  const user_id = loginUserDetail?.user_id;
  const access_token = loginUserDetail?.access_token;

  if (user_id && access_token) {
    const res = await getLoginUserDetail({ user_id, access_token });
    if (res?.status) {
      const location = window.location.search;
      const params = new URLSearchParams(location)
      let redirectURL = params.get('redirect_url');
      if (redirectURL) {
        redirectURL = redirectURL.split("?");
      }

      if (res?.tsp?.is_admin) {
        window.location.replace(`${process.env.REACT_APP_ADMIN_NATURE_URL}?user_id=${user_id}&access_token=${access_token}`);
      } else if (redirectURL && redirectURL[0]) {
        window.location.replace(`${redirectURL[0]}?user_id=${user_id}&access_token=${access_token}`);
      } else {
        window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}?user_id=${user_id}&access_token=${access_token}`);
      }
    }
  }

  setIsLoading(false)
}

export {
  isLogin
}