/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAlert from "../../../hooks/use-alert";
import loginSubmit from "../../../utils/login_submit";
import { validateQrCodeAPI } from "../services/submitOTPService";
import { getTSPIds } from "../../../utils/token";
import { setUserLoginDetail } from "../../login/redux/loginAction";

const SubmitQrCodeForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { loginUserDetail } = useSelector((state) => state.loginReducer);

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    confirmationCode: "",
  };

  const formSchema = Yup.object().shape({
    confirmationCode: Yup.string()
      .trim()
      .required("Please enter confirmation code")
      .test('len', 'Confirmation code must be exactly 6 digits', val => val.length === 6)
      .test('len', 'Confirmation code must be number', val => new RegExp(/^\d+$/).test(parseInt(val))),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const data = {
        user_id: loginUserDetail?.user_id,
        access_token: loginUserDetail?.access_token,
        aws_sso_token: loginUserDetail?.aws_access_token,
        qr_code: values.confirmationCode,
        aws_sso_session: loginUserDetail?.aws_session,
        user_name: loginUserDetail?.user_email,
        // multi_tsp_user_ids: getTSPIds(loginUserDetail?.multi_tsp),
        enabled: true,
      };
      const res = await validateQrCodeAPI(data);
      if (res.status) {
        const result = await loginSubmit(res, dispatch, showAlert, "submit");
        const urlParams = new URLSearchParams(window.location.search);
        const redirect_url = urlParams.get('redirect_url');

        const user_id = loginUserDetail?.user_id;
        const access_token = loginUserDetail?.access_token;

        if (loginUserDetail?.is_admin) {
          window.location.replace(`${process.env.REACT_APP_ADMIN_NATURE_URL}?user_id=${user_id}&access_token=${access_token}`);
        } else if (result && result === "/dashboard" && redirect_url) {
          window.location.replace(`${redirect_url}?user_id=${user_id}&access_token=${access_token}`);
        } else if (result && result === "/dashboard") {
          window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}?user_id=${user_id}&access_token=${access_token}`);
        } else {
          navigate(result);
        }
      } else {
        showAlert("ERROR", "ERROR", res.message);
      }

      setIsLoading(false);
    },
  });

  const handleOTPChange = (code) => {
    formik.setFieldValue("confirmationCode", code);
  }

  const clearLoginUserDetail = () => {
    dispatch(setUserLoginDetail({}));
  }

  return (
    <form action="" className="form-style">
      <div className="row">
        <div className="col-12 mb-4">
          <label htmlFor="confirmationCode" className="form-label font-14 font-w-500 font-292930">
            Confirmation code
          </label>
          <div className="otp-inputs">
            <OtpInput
              value={formik.values.confirmationCode === undefined ? "" : formik.values.confirmationCode}
              onChange={handleOTPChange}
              numInputs={6}
              name="confirmationCode"
              separator={<span style={{ width: "8px", marginRight: "5px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              inputType="number"
              renderInput={(props) => <input {...props} />}
            />
          </div>
          {(formik.touched.confirmationCode && formik.errors.confirmationCode) && (
            <p className="text-danger mb-0">
              <small>{formik.errors.confirmationCode}</small>
            </p>
          )}
        </div>

        <div className="col-12 mt-4">
          {
            isLoading ?
              <button type="submit" className="btn mx-auto" disabled>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </button>
              :
              <button type="submit" onClick={formik.handleSubmit} className="btn btn-submit mx-auto">Authenticate Now</button>
          }
        </div>
        <div className="col-12 mt-4 text-center" onClick={() => clearLoginUserDetail()}>
          <NavLink to="/login" className="text-decoration-none fw-semibold text-green">
            <svg xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink" style={{ height: "25px", width: "25px" }} version="1.1" id="Layer_1"
              x="0px" y="0px" viewBox="0 0 512 512" xmlSpace="preserve">
              <g id="_19">
                <path
                  d="M255.8,405.4c-5.6,0-11-2.2-14.9-6.2l-128-128c-8.3-8.3-8.3-21.8,0-30.1l128-128c8-6.8,19.8-6.8,27.8,0   c8.9,7.7,10,21.1,2.3,30.1L157.9,256L271,368.9c4.1,4,6.3,9.5,6.3,15.3C277.2,395.9,267.6,405.4,255.8,405.4L255.8,405.4z" />
                <path
                  d="M368.7,399l-128-128c-8.3-8.3-8.3-21.8,0-30.1l128-128c8.4-8.4,21.9-8.4,30.3,0s8.4,21.9,0,30.3L285.9,256L399,368.9   c4.1,4,6.3,9.5,6.3,15.3c-0.1,11.8-9.7,21.3-21.5,21.2C378.1,405.4,372.7,403,368.7,399z" />
              </g>
            </svg>
            Back to Log In
          </NavLink>
        </div>
      </div>
    </form>
  );
};

export default SubmitQrCodeForm;
