import React from "react";
import SubmitQrCodeForm from "./components/submit-otp-form";

const SubmitOtp = () => {

  return (
    <div className="page-content bg-style no-sidebar w-100 float-start">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-5 mb-4">
            <div className="content-box-style">
              <h4 className="text-uppercase text-center">Google Authenticator</h4>
              <p className="font-16 font-w-400 font-999FAE mb-lg-5 text-center">
                Please use google authentication app for 2 Factor
                Authentication, OTP enter below to Authenticate your Identity
              </p>
              <SubmitQrCodeForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitOtp;
