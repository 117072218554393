/* eslint-disable default-param-last */
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import { homeConstants } from "./homeAction";

const initialState = {
  otpScreenDetail: {},
};

const persistConfig = {
  storage,
  key: "sunset-home",
  whitelist: ["otpScreenDetail"],
  transforms: [
    encryptTransform({
      secretKey: 'sunset-account-persist-secure',
      onError: function (error) {
        // Handle the error.
      }
    })
  ]
};

const homeReducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case homeConstants.SET_OTP_SCREEN_DETAIL:
      return {
        ...state,
        otpScreenDetail: action.payload,
      };

    default:
      return state;
  }
});

export default homeReducer;