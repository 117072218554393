export const homeConstants = {
  SET_OTP_SCREEN_DETAIL: "SET_OTP_SCREEN_DETAIL",
}

const setOtpScreenDetail = (values) => {
  return {
    type: homeConstants.SET_OTP_SCREEN_DETAIL,
    payload: values
  };
};
export {
  setOtpScreenDetail,
}